<template>
    <div class="content">
        <div class="content_top" style="display: flex;justify-content: space-between">
            <div style="display: flex;">
                <router-link to="/"><div class="flex" style="width: 139px; height: 50px; background: #022358; border-radius: 25px; margin-top: 20px; margin-bottom: 20px; margin-left: 10px;">
                    <div><img style="width: 30px; height: 30px; margin-top: 10px;  margin-left: 18px;" src="../assets/images/zym.png"></div>
                    <div style="font-size: 20px; font-weight: 500; color: #ffffff; line-height: 50px; margin-left: 10px;">主页面</div>
                </div></router-link>
                <b></b>
                <!--                <p @click="jump($event)">{{ $route.meta.title }}</p>-->
                <div style="margin-top: 35px; margin-left: 20px;"><img src="../assets/images/zuo.png"></div>
                <p style="display: block; margin-top: 30px; margin-left: 20px;color: #1E87F0; font-size: 20px;" v-if="$route.meta.subtitle">
                    {{ $route.meta.subtitle }}</p>
            </div>

            <el-form class="search-bar" ref="form" :inline="true" :model="form" style="display: flex;">
                <el-form-item prop="sta_type">
                    <el-select v-model="form.sta_type" placeholder="请选择训练类型" style="width: 100%;" @change="educationChange($event)">
                        <el-option v-for="item in staList" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="keyword">
                    <el-input v-model="form.keyword" placeholder="输入关键词" clearable></el-input>
                </el-form-item>
                <div class="date-search">
                    <el-col :span="11">
                        <el-form-item prop="start_time">
                            <el-date-picker type="date" placeholder="选择日期" v-model="form.start_time"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="line" :span="1">-</el-col>
                    <el-col :span="11">
                        <el-form-item prop="end_time">
                            <el-date-picker type="date" placeholder="选择日期" v-model="form.end_time" style="width: 100%;">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </div>
                <el-form-item>
                    <el-button type="primary" round @click="onSubmit"
                               style="background: #01B2E4;width:90px;border:none">搜索
                    </el-button>
                </el-form-item>
                <el-form-item v-if="!echartsShow">
                    <el-button class="pink" plain @click="backPage" icon="el-icon-arrow-left"style="color: #01B2E4!important;" >
                        <a style="color: #333333!important;"> 返回主页面</a>
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="content_center" v-if="echartsShow">
            <div>
                <div class="content_center_text">患者数</div>
                <div class="content_center_text" style="color: #20BE18">{{ formData.patient.all }}</div>
                <div class="content_center_text" style="display: flex;justify-content: center;align-items: center">
                    <img src="../assets/images/icon1_color.png" v-if="formData.patient.down!==0">
                    <img src="../assets/images/icon1.png" v-else>
                    <div style="margin-right: 30px">{{ formData.patient.down }}</div>
                    <img src="../assets/images/icon2.png"v-if="formData.patient.up!==0">
                    <img src="../assets/images/icon2_color.png" v-else>
                    <div>{{ formData.patient.up }}</div>
                </div>
            </div>
            <div class="content_center_line"></div>
            <div>
                <div class="content_center_text">门诊患者</div>
                <div class="content_center_text" style="color: #F4C424">{{ formData.outpatient.all }}</div>
                <div class="content_center_text" style="display: flex;justify-content: center;align-items: center">
                    <img src="../assets/images/icon3_color.png" v-if="formData.outpatient.down!==0">
                    <img src="../assets/images/icon3.png" v-else>
                    <div style="margin-right: 30px">{{ formData.outpatient.down }}</div>
                    <img src="../assets/images/icon4_color.png"v-if="formData.outpatient.up!==0">
                    <img src="../assets/images/icon4.png" v-else>
                    <div>{{ formData.outpatient.up }}</div>
                </div>
            </div>
            <div class="content_center_line"></div>
            <div>
                <div class="content_center_text">住院患者</div>
                <div class="content_center_text" style="color: #FF8F5F">{{ formData.hospitalization.all }}</div>
                <div class="content_center_text" style="display: flex;justify-content: center;align-items: center">
                    <img src="../assets/images/icon5_color.png" v-if="formData.hospitalization.down!==0">
                    <img src="../assets/images/icon5.png" v-else>
                    <div style="margin-right: 30px">{{ formData.hospitalization.down }}</div>
                    <img src="../assets/images/icon6_color.png" v-if="formData.hospitalization.up!==0">
                    <img src="../assets/images/icon4.png" v-else>
                    <div>{{ formData.hospitalization.up }}</div>
                </div>
            </div>
            <div class="content_center_line"></div>
            <div>
                <div class="content_center_text">男女比例</div>
                <div class="content_center_text" style="color: #39CBE9">{{ formData.sex.all }}</div>
                <div class="content_center_text" style="display: flex;justify-content: center;align-items: center">
                    <img src="../assets/images/icon7.png" style="width: 11px">
                    <div style="margin-right: 30px">{{ formData.sex.man }}</div>
                    <img src="../assets/images/icon8.png"style="width: 11px">
                    <div>{{ formData.sex.girl }}</div>
                </div>
            </div>
            <div class="content_center_line"></div>
            <div>
                <div class="content_center_text">单人训练数</div>
                <div  class="content_center_text" style="color: #20BE18">{{ formData.emotion.all }}</div>
                <div class="content_center_text" style="display: flex;justify-content: center;align-items: center">
                    <img src="../assets/images/icon9_color.png" v-if="formData.emotion.down !==0">
                    <img src="../assets/images/icon5.png" v-else>
                    <div style="margin-right: 30px">{{ formData.emotion.down }}</div>
                    <img src="../assets/images/icon10_color.png" v-if="formData.emotion.up !==0">
                    <img src="../assets/images/icon4.png" v-else>
                    <div>{{ formData.emotion.up }}</div>
                </div>
            </div>
            <div class="content_center_line"></div>
            <div>
                <div class="content_center_text">对抗训练数</div>
                <div  class="content_center_text" style="color: #01B2E4">{{ formData.emotion1.all }}</div>
                <div class="content_center_text" style="display: flex;justify-content: center;align-items: center">
                    <img src="../assets/images/icon11_color.png" v-if="formData.emotion1.down !==0">
                    <img src="../assets/images/icon5.png" v-else>
                    <div style="margin-right: 30px">{{ formData.emotion1.down }}</div>
                    <img src="../assets/images/icon12_color.png" v-if="formData.emotion1.up !==0">
                    <img src="../assets/images/icon4.png" v-else>
                    <div>{{ formData.emotion1.up }}</div>
                </div>
            </div>
            <div class="content_center_line"></div>
            <div>
                <div class="content_center_text">团体训练数</div>
                <div  class="content_center_text" style="color: #1E86EF">{{ formData.emotion2.all }}</div>
                <div class="content_center_text" style="display: flex;justify-content: center;align-items: center">
                    <img src="../assets/images/icon13_color.png" v-if="formData.emotion2.down !==0">
                    <img src="../assets/images/icon5.png" v-else>
                    <div style="margin-right: 30px">{{ formData.emotion2.down }}</div>
                    <img src="../assets/images/icon14_color.png" v-if="formData.emotion2.up !==0">
                    <img src="../assets/images/icon4.png" v-else>
                    <div>{{ formData.emotion2.up }}</div>
                </div>
            </div>
        </div>
        <div class="charts-wrap" v-if="echartsShow">
            <div id="myChart" :style="{width: '100%', height: '595px'}"></div>
        </div>
        <el-table
            v-if="!echartsShow"
            v-loading="loading"
            style="width: 100%"
            class=""
            stripe
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
        >
            <el-table-column
                center
                type="selection"
                align="center"
                width="60"
            >
            </el-table-column>
            <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                             :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
            </el-table-column>
        </el-table>
        <div class="bottom_bottom" v-if="!echartsShow">
            <div class="page_total_wrap">
                <div class="page_total">
                    <span class="total-count">共{{ searchData.total_count }}项</span>
                    <span class="total-tip">
						当前显示{{ searchData.start_count }}到{{ searchData.end_count }}项，共{{ searchData.end_count }}项
					</span>
                </div>
            </div>
            <div class="bottom_bottom_box">
                <div></div>
                <el-pagination @current-change="handleCurrentChange" :current-page.sync="form.page" :pager-count="9"
                               layout="prev, pager, next, jumper" :hide-on-single-page="true" :total="searchData.total_count">
                </el-pagination>
                <a class="report" href="javascript:;" @click="AdownloadClick" style="color: #FFFFFF;" v-if="multipleSelection.length==0">
                    <img src="../assets/images/xiazai1.png" alt="">
                    下载
                </a>
<!--                <a class="report" :href="'http://192.168.0.189/api/Analysis/index_list?type=1&exam_id='-->
<!--                +multipleSelection+'&hospital_id='-->
<!--                +formData.hospital_id +'&sta_type='+form.sta_type +'&sta='+form.sta"-->
<!--                style="color: #FFFFFF" v-else>-->
<!--                    <img src="../assets/images/xiazai1.png" alt="">-->
<!--                    下载-->
<!--                </a>-->
              <a class="report" :href="'http://swfk.dxtk-tech.com/api/Analysis/index_list?type=1&exam_id='
                +multipleSelection+'&hospital_id='
                +formData.hospital_id +'&sta_type='+form.sta_type +'&sta='+form.sta"
                 style="color: #FFFFFF" v-else>
                <img src="../assets/images/xiazai1.png" alt="">
                下载
              </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DataAnalysis",
    data() {
        return {
            form: {
                keyword: '',
                page: 1,
                limit: 10,
                start_time: '',
                end_time: '',
                sta_type:'',
                sta:3
            },
            searchData: [],
            tableTitle: [
                {
                    name: '序号',
                    value: 'num',
                    width:'80'
                },
                {
                    name: '训练时间',
                    value: 'exam_create_at',
                    width:'200'
                },

                {
                    name: '患者姓名',
                    value: 'name',
                    width:'140'
                },
                {
                    name: '性别',
                    value: 'gender',
                    formatter: (row) => {
                        if (row.gender == 0) {
                            return '男';
                        } else {
                            return '女';
                        }
                    },
                    width:'130'
                },
                {
                    name: '年龄',
                    value: 'age',
                    width:'110'
                },
                {
                    name: '病历号',
                    value: 'medical_num',
                    width:'130'
                },
                {
                    name: '医保号',
                    value: 'medical_insurance_num',
                    width:'130'
                },
                {
                    name: '患者来源',
                    value: 'source',
                    width:'130'
                },

                {
                    name: '入院时间',
                    value: 'in_time',
                    width:'160'
                },
                // {
                //     name: '户口类型',
                //     value: 'hukou',
                //     width:'120'
                // },
                {
                    name: '手机号',
                    value: 'phone',
                    width:'150'
                },
                // {
                //     name: '民族',
                //     value: 'nation_name',
                //     width:'100'
                // },
                // {
                //     name: '婚姻',
                //     value: 'marriage_name',
                //     width:'90'
                // },
                // {
                //     name: '学历',
                //     value: 'education_name',
                //     width:'90'
                // },
                // {
                //     name: '职业',
                //     value: 'occupation_name',
                //     width:'90'
                // },
                {
                    name: '训练名称',
                    value: 'test_paper_name',
                },
                // {
                //     name: '测评结果',
                //     value: 'score_all',
                //     width: '200'
                // },
            ],
            tableData: [],
            InfoHref: 'InfoAssess',
            echartsShow:true,
            loading: false,
            formData:{
                hospitalization:{
                    all:'',
                    down:'',
                    up: 0
                },
                outpatient:{
                    all:'',
                    down:'',
                    up: 0
                },
                patient:{
                    all:'',
                    down:'',
                    up: 0
                },
                sex:{
                    all:'',
                    girl:'',
                    man: 0
                },
                emotion:{
                    all:'',
                    down:'',
                    up: 0
                },
                emotion1:{
                    all:'',
                    down:'',
                    up: 0
                },
                emotion2:{
                    all:'',
                    down:'',
                    up: 0
                },
            },
            multipleSelection:[],
            staList:[
                {
                    id:1,
                    name:'单人训练'
                },
                {
                    id:2,
                    name:'对抗训练'
                },
                {
                    id:3,
                    name:'团体训练'
                },
            ]

        };
    },
    computed: {},
    watch: {},
    mounted() {
        //获取权限列表
        this.$store.dispatch('GetAuth')
        //消息接口
        this.$store.dispatch('GetXiao')
        this.listInterface()
    },
    methods: {
        educationChange(e){
            this.form.sta_type= e
        },
        AdownloadClick() {
            if (this.multipleSelection.length === 0) {
                this.$message({
                    type: 'info',
                    message: '请先选择一条信息'
                });
                return;
            }
            // window.open(
            //     'http://xlct.dxtk-tech.com/api/Analysis/index_list?type=1&exam_id='
            //     +this.multipleSelection+'&hospital_id='
            //     +this.formData.hospital_id
            // )
        },
        listInterface() {
            this.$axios.post('api/Analysis/index').then(res => {
                this.formData = res.data.data
                this.$nextTick(() => {
                    this.drawLine();
                })
            })
        },
        listTable() {
            this.$axios.post('api/Analysis/index_list', this.$qs.stringify(
                this.form
            )).then(res => {
                let data = res.data.data;
                this.searchData = data;
                this.tableData = data.list;
                // console.log("~ this.tableData", this.tableData)
                if (data.list == null) {
                    this.tableData = data;
                }
                setTimeout(() => {
                    this.loading = false
                },2000)
            })
        },
        handleCurrentChange(val) {
            this.listTable()
        },
        // 搜索
        onSubmit() {
            if(this.form.sta_type == '') {
                this.form.sta_type = 1
            }
            this.form.page = 1
            this.form.hospital_id = this.formData.hospital_id
            this.echartsShow = false
            this.listTable()
        },
        handleSelectionChange(val) {
            let ids = val.map(item => {
                return item.exam_id
            })
            this.multipleSelection = ids;
        },
        backPage() {
            this.echartsShow = true
            this.$nextTick(() => {
                this.drawLine();
            })
        },
        // echarts图
        drawLine(){
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById('myChart'))
            // 绘制图表
            myChart.setOption({
                title: {
                    text: '一周内',
                    textStyle:{
                        //文字颜色
                        color:'#ffffff',
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#8CA0B3'
                        }
                    }
                },
                color:['#09ACF8','#507DEC','#20BE18','#E7848A'],
                legend: {
                    data: ['患者数', '单人训练数', '对抗训练数', '团体训练数'],
                    textStyle:{
                        color: '#ffffff'//字体颜色
                    },

                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: this.formData.data.date
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '团体训练数',
                        type: 'line',
                        stack: 'Total',
                        emphasis: {
                            focus: 'series'
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color:  '#09ACF8'
                                },
                                {
                                    offset: 1,
                                    color: '#F5FBFE'
                                }
                            ])
                        },
                        data: this.formData.data.emotion2,
                    },
                    {
                        name: '对抗训练数',
                        type: 'line',
                        stack: 'Total',
                        emphasis: {
                            focus: 'series'
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color:  '#507DEC'
                                },
                                {
                                    offset: 1,
                                    color: '#F0F5FF'
                                }
                            ])
                        },
                        data: this.formData.data.emotion1,
                    },
                    {
                        name: '单人训练数',
                        type: 'line',
                        stack: 'Total',
                        emphasis: {
                            focus: 'series'
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color:  '#FA6D68'
                                },
                                {
                                    offset: 1,
                                    color: '#FFF7F6'
                                }
                            ])
                        },
                        data: this.formData.data.emotion,
                    },
                    {
                        name: '患者数',
                        type: 'line',
                        stack: 'Total',
                        emphasis: {
                            focus: 'series'
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color:  '#D3EEFC'
                                },
                                {
                                    offset: 1,
                                    color: '#F5FBFE'
                                }
                            ])
                        },
                        data: this.formData.data.patient,

                    },

                ]
            });
        }
    },

}
</script>
<style>
</style>
<style scoped lang="scss">

.content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 150px) !important;

}
.content_center_line {
    width: 1px!important;
    height: 90px;
    border: 0.1px dashed #cccccc;

}
.content_center {
    width: 100%;
    padding: 30px 0px;
    background: #ffffff;
    //margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(8,51,145,0.3);
    border: 1px solid #01C2F4;
    border-bottom: none;
}
.content_center >div {
    width: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    color: #FFFFFF;
}
.content_center >div .content_center_text {
    width: 100%;
}
.content_center >div .content_center_text img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.content_center >div  >div:nth-child(1) {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.content_center >div  >div:nth-child(2) {
    font-size: 60px;
    font-weight: 400;
    margin-bottom: 10px;
}
.content_center >div  >div:nth-child(3) {
    font-size: 16px;
    font-weight: 400;
}
.date-search {
    display: inline-block;
}

.date-search ::v-deep .el-form-item {
    margin-right: 0;
}
 ::v-deep .el-form-item {
    margin-bottom: 0;
}
.line {
    color: #CCC;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
}
.content_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    border: 1px solid #01C2F4;
    //height: 60px;
    //display: flex;
    //align-items: center;
    //justify-content: flex-end;
}
.charts-wrap {
    padding: 20px;
    background: rgba(8,51,145,0.3);
    border: 1px solid #01C2F4;
    border-top: none;
}
.bottom_bottom {
    padding: 30px 45px;
    background-color: #FFF;
}


.bottom_bottom .page_total_wrap {
    margin-bottom: 11px;
    display: flex;
    justify-content: space-between;
}
.page_total {
    display: flex;
    align-items: center;
}
.bottom_bottom .total-count {
    background: #F2ECFC;
    color: #7A40F2;
    font-size: 14px;
    border-radius: 50px;
    text-align: center;
    margin-right: 15px;
    padding: 6px 14px;

}

.bottom_bottom .total-tip {
    font-size: 14px;
    color: #444444;
}
.bottom_bottom a {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 15px;
    background: #39CBE9;
    border-radius: 4px;
}
.bottom_bottom_box {
    display: flex;
    justify-content: space-between;
}
.bottom_bottom a img {
    display: block;
    margin: 10px auto 0;
}
.bottom_bottom a.report {
    width: 138px;
    line-height: 48px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 23px;
        height: 20px;
        margin: 0 5px 0 0;
    }
}
.bottom_bottom .el-pagination {
    display: flex;
    justify-content: center;
}
::v-deep {
    .el-select .el-input .el-select__caret {
        margin-right: 0!important;
    }
    .el-button--text {
        color: #ffffff;
    }
    .el-pagination .btn-prev .el-icon, .el-pagination .btn-next .el-icon {
        color: #ffffff;
    }
    .el-pagination button:hover {
        color: #60A7EB ;
    }
    .el-pagination__editor.el-input .el-input__inner {
        border-radius: 2px;
        border: 1px solid #60A7EB;
        color: #ffffff;
    }

    .el-pager li {
        background: none;
        color: #FFFFFF;
    }
    .el-pagination .btn-prev, .el-pagination .btn-next {
        background: none;
    }
    .el-pagination button:disabled {
        background: none;
    }
    .el-table__header {
        width: 100% !important;
    }
    .el-table__body {
        width: 100% !important;
    }
    .el-table tr {
        height: 76px;
        background: #02174d;
        color: #ffffff;
        font-size: 16px;
    }
    .el-table th {
        background: #022358;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
        background: #022358;
        border-bottom: none;
    }
    .el-table th.is-leaf, .el-table td {
        border-bottom: none !important;
    }
    .el-table .el-table__row:hover > td {
        background-color: #022358 !important;
    }
    .el-button--primary {
        background-color: #01B2E4;
        border-color: #01B2E4;
        border-radius: 25px;
        font-size: 16px;
    }
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #1E87F0;
        border-color: #1E87F0;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
        background: #01B2E4;
        border-color: #60A7EB;;
    }
    .el-input__inner {
        width: 100%!important;
        background: #022358!important;
        border: #022358!important;
        border-radius: 25px;
        border: 1px solid #022358;
    }
}
.el-table {
    width: 100%;
    background: rgba(8, 51, 145, 0.3);
    border: 1px solid #01C2F4;
    border-bottom: none;
    overflow-y: auto;
}
.table_list th {
    font-size: 16px;
}

.table_list td {
    font-size: 14px;
}

.table_list tr {
    height: 55px;
}

.table_list tr:nth-child(odd) {
    background: #F4F4F4;
}

.table_list th, td {
    width: 157px;
}

td:last-child {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 35%;
}

.table_list img {
    display: block;
    margin-right: 10px;
}

.table_list img:last-child {
    margin: 0;
}


.page_top {
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: #444;
    font-size: 14px;
}

.page_top span {
    background: #FEE7E6;
    color: #1E87F0;
    font-size: 14px;
    border-radius: 50px;
    height: 25px;
    line-height: 25px;
    width: 85px;
    text-align: center;
    margin-right: 15px;
}

.page_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.page_box a {
    width: 35px;
    height: 35px;
    border: 1px solid #CCCCCC;
    font-size: 16px;
    color: #444;
    text-align: center;
    line-height: 35px;
    margin-right: 5px;
}

.page_box a.active {
    background: #FEE7E6;
    color: #1E87F0;
    border: 1px solid #1E87F0;
}

.page_box p {
    margin: 0 20px;
    font-size: 16px;
    color: #555;
}

.page_box p input {
    width: 35px;
    height: 35px;
    border: 1px solid #CCCCCC;
    font-size: 16px;
    color: #555;
    text-align: center;
    line-height: 35px;
}

.page_box .sure {
    display: block;
    width: 110px;
    text-align: center;
    color: #FFF;
    font-size: 16px;
    margin-left: 25px;
    height: 35px;
    line-height: 35px;
    background: #1E87F0;
    box-shadow: 2px 3px 5px 0px rgba(245, 96, 90, 0.2);
    border-radius: 50px;
}

::v-deep .el-form-item {
    margin-bottom: 0;
}

.line {
    color: #CCC;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
}

.bottom_bottom {
    display: flex;
    justify-content: space-between;
    padding: 30px 45px;
    background: rgba(8, 51, 145, 0.3);
    border: 1px solid #01C2F4;
    border-top: none;
}


.bottom_bottom .page_total_wrap {
    margin-bottom: 11px;
    display: flex;
    justify-content: space-between;
}

.page_button {

}

.page_total {
    display: flex;
    align-items: center;
}

.bottom_bottom .total-count {
    background: none;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    margin-right: 5px;
    padding: 6px 14px;

}

.bottom_bottom .total-tip {
    font-size: 14px;
    color: #444444;
}

.bottom_bottom .el-pagination {
    display: flex;
    justify-content: center;
}

::v-deep .cell-class {
    text-align: center;
    font-size: 16px;
}

.date-search {
    display: inline-block;
}

.date-search ::v-deep .el-form-item {
    margin-right: 0;
}

::v-deep .el-table::before {
    height: 0;
}

.right-btn {
    float: right;

    .el-button {
        width: 149px;
        height: 50px;
        ont-size: 18px;
        color: #FFFFFF;
        border-radius: 25px;
    }

    .el-button:first-child {
        background: #F39334;
    }

    .el-button:last-child {
        background: #1E87F0;
    }
}
</style>
